@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/woi2bws.css");

body {
  /* font-family: "adobe-garamond-pro", serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lot{
  text-shadow: 0px 0px 0px white;
}

/* code {
  font-family: "adobe-garamond-pro", serif;
} */
